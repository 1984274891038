<template>
  <div>
    <div v-if="!claimed">
      <TaskBlocker :assignee="data.assignee" />
    </div>

    <div
      v-if="taskDataLoading"
      class="ma-9"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
      Task Daten werden geladen
    </div>

    <div v-else>
      <v-stepper
        flat
        alt-labels
        :value="currentStep"
      >
        <v-stepper-header>
          <template v-for="(step, index) in steps">
            <v-divider
              v-if="index > 0"
              :key="index + '-divider'"
            ></v-divider>
            <v-stepper-step
              :key="index"
              :step="step.step"
              :complete="step.step < currentStep"
              :color="getStepColor(step, currentStep)"
            >
              <span class="text-center">
                <p>{{ step.name }}</p>
                <p v-if="step.changeDate">{{ formatDate(step.changeDate) }}</p>
              </span>
            </v-stepper-step>
          </template>
        </v-stepper-header>
      </v-stepper>

      <v-expansion-panels
        :value="false"
        class="mb-6"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span>
              <span>Projekt Details </span>
              <span v-if="variables && variables.projectId">{{ variables.projectId }}</span>
              <span v-if="project && project.name"> - {{ project.name }}</span></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ProjectDetails
              :project="project"
              hideCleverReachButton
              hideBrevoButton
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-alert
        v-if="!isCurrentStepValid"
        elevation="5"
        type="error"
        border="left"
        outlined
        prominent
      >
        <span>Das Projekt <b>{{ project.projektId }} - {{ project.name }}</b> hat nicht den erwarteten Projekt Status.</span><br />
        <span>
          Aktueller Projekt Status <b :style="{color: project.projectStatus.color}">{{ project.projectStatus.description }}</b>,
          erwartet wird <b :style="{color: expectedStatus.color}">{{ expectedStatus.description }}</b>!
        </span><br />
        <span><v-icon color="blue">mdi-information-variant</v-icon> Die Aufgabe kann nicht bearbeitet werden, bis der Status dem erwarteten Status entspricht</span>
      </v-alert>

      <ActionFreigabeProjektplanung
        v-if="variables && data.taskDefinitionKey === 'FreigabeProjektplanungTask'"
        :key="data.id"
        :data="data"
        :variables="variables"
        :editable="isEditable"
      />
      <ActionFreigabeProjektstart
        v-else-if="variables && data.taskDefinitionKey === 'FreigabeProjektstartTask'"
        :key="data.id"
        :data="data"
        :variables="variables"
        :editable="isEditable"
      />
      <ActionFreigabeProjektstartBestaetigen
        v-else-if="variables && data.taskDefinitionKey === 'FreigabeProjektstartBestaetigenTask'"
        :key="data.id"
        :data="data"
        :variables="variables"
        :editable="isEditable"
      />
      <ActionFreigabeProjektZumTiefbauBereit
        v-else-if="variables && data.taskDefinitionKey === 'FreigabeProjektZumTiefbauBereitTask'"
        :key="data.id"
        :data="data"
        :variables="variables"
        :editable="isEditable"
      />
      <ActionFreigabeProjektZumTiefbauBereitBestaetigen
        v-else-if="variables && data.taskDefinitionKey === 'FreigabeProjektZumTiefbauBereitBestaetigenTask'"
        :key="data.id"
        :data="data"
        :variables="variables"
        :editable="isEditable"
      />
      <ActionProjektAbgeschlossen
        v-else-if="variables && data.taskDefinitionKey === 'ProjektAbgeschlossenTask'"
        :key="data.id"
        :data="data"
        :variables="variables"
        :editable="isEditable"
      />
    </div>
  </div>
</template>

<script>
import NotificationObject from '@/main/NotificationObject.js';
import { HTTP } from '@/main/httpClient';
import TimeUtility from '@/util/TimeUtility.js';

import TaskBlocker from '@/task/TaskBlocker.vue';
import ProjectDetails from '@/components/constructionProjects/ProjectDetails.vue';

const STEPS = {
  APPROVED: 'project-approved',
  PLANNED: 'project-planned',
  STARTED: 'project-started',
  CONSTRUCTION_STARTED: 'project-construction-started',
  READY: 'project-ready'
};

export default {
  name: 'ProjectAndBuildStatusView',
  components: {
    TaskBlocker,
    ProjectDetails,
    ActionFreigabeProjektplanung: () =>
      import('./ActionFreigabeProjektplanung.vue'),
    ActionFreigabeProjektstart: () =>
      import('./ActionFreigabeProjektstart.vue'),
    ActionFreigabeProjektstartBestaetigen: () =>
      import('./ActionFreigabeProjektstartBestaetigen.vue'),
    ActionFreigabeProjektZumTiefbauBereit: () =>
      import('./ActionFreigabeProjektZumTiefbauBereit.vue'),
    ActionFreigabeProjektZumTiefbauBereitBestaetigen: () =>
      import('./ActionFreigabeProjektZumTiefbauBereitBestaetigen.vue'),
    ActionProjektAbgeschlossen: () => import('./ActionProjektAbgeschlossen.vue')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    taskDataLoading: true,
    variables: undefined,
    project: undefined,
    projectStatusList: [],
    steps: [
      {
        step: 1,
        name: 'Freigabe Projektplanung',
        tasks: ['FreigabeProjektplanungTask'],
        expectedProjectStatus: STEPS.APPROVED,
        nextProjectStatus: STEPS.PLANNED
      },
      {
        step: 2,
        name: 'Freigabe Projektstart',
        tasks: [
          'FreigabeProjektstartTask',
          'FreigabeProjektstartBestaetigenTask'
        ],
        expectedProjectStatus: STEPS.PLANNED,
        nextProjectStatus: STEPS.STARTED
      },
      {
        step: 3,
        name: 'Freigabe Projekt zum Tiefbau bereit',
        tasks: [
          'FreigabeProjektZumTiefbauBereitTask',
          'FreigabeProjektZumTiefbauBereitBestaetigenTask'
        ],
        expectedProjectStatus: STEPS.STARTED,
        nextProjectStatus: STEPS.CONSTRUCTION_STARTED
      },
      {
        step: 4,
        name: 'Projekt abgeschlossen',
        tasks: ['ProjektAbgeschlossenTask'],
        expectedProjectStatus: STEPS.CONSTRUCTION_STARTED,
        nextProjectStatus: STEPS.READY
      }
    ]
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    isCamundaAdmin() {
      return this.$auth.user().userRoles.includes('camunda-admin');
    },
    isEditable() {
      return (
        this.claimed &&
        !this.taskDataLoading &&
        (this.isCurrentStepValid || this.isCamundaAdmin)
      );
    },
    currentStepObject() {
      for (const step of this.steps) {
        if (step.tasks.includes(this.data.taskDefinitionKey)) {
          return step;
        }
      }
      return null;
    },
    currentStep() {
      return this.currentStepObject.step;
    },
    expectedStatus() {
      return this.currentStepObject.expectedProjectStatus;
    },
    nextStatus() {
      return this.currentStepObject.nextProjectStatus;
    },
    isCurrentStepValid() {
      if (!this.project || !this.expectedProjectStatus) return false;
      return (
        this.project.projectStatus.name === this.expectedProjectStatus.name
      );
    },
    isConfirmationTask() {
      if (!this.currentStepObject) return false;
      const stepTasks = this.currentStepObject.tasks;

      if (stepTasks.length <= 1) return false;
      return stepTasks[1] === this.data.taskDefinitionKey;
    }
  },
  mounted: async function () {
    // fix race condition after reopen the same task
    await new Promise((resolve) => {
      setTimeout(resolve, 1);
    });

    const promises = [
      this.data.variables.then((variables) => {
        this.variables = Object.keys(variables).reduce((obj, key) => {
          obj[key] = variables[key].value;
          return obj;
        }, {});

        if (!this.variables.projectStatusDateMap) {
          this.variables.projectStatusDateMap = {};
        }

        return HTTP.get(
          '/past/projects?where[projektId][equals]=' + this.variables.projectId
        ).then((response) => {
          this.project = response.data[0];
          this.variables.$project = this.project;
        });
      }),
      HTTP.get('/past/projectStatuses').then(({ data: statusList }) => {
        this.projectStatusList = statusList;
        this.steps.map((step) => {
          step.expectedProjectStatus = statusList.find(
            (s) => s.name === step.expectedProjectStatus
          );
          step.nextProjectStatus = statusList.find(
            (s) => s.name === step.nextProjectStatus
          );
          if (!step.expectedProjectStatus || !step.nextProjectStatus) {
            throw new Error(
              'Past project status response does not contains all required status'
            );
          }
          return step;
        });
        this.variables.$steps = this.steps;
        this.variables.$currentStep = this.currentStepObject;
      })
    ];

    Promise.all(promises)
      .then(() => {
        this.steps.map((step) => {
          step.changeDate = this.parseDate(
            this.variables.projectStatusDateMap[step.nextProjectStatus.name]
          );
        });
        this.taskDataLoading = false;
      })
      .catch((err) => {
        console.error(err);
        this.showMessage(
          'error',
          'Beim laden der Aufgabe ist ein Fehler aufgetreten'
        );
      });
  },
  methods: {
    getStepColor(step, currentStep) {
      if (step.step < currentStep) return 'success';
      if (this.isConfirmationTask) return 'deep-orange lighten-1';
      if (step.step === currentStep) return 'primary';
      return 'black';
    },
    parseDate(date) {
      if (!date) return;
      return Date.parse(date);
    },
    formatDate(date) {
      if (!date) return;
      return TimeUtility.formatLocalDate(date);
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    submitTask: function (submitObject) {
      return HTTP.post(
        '/projectConstructionState/planningOrConstructionStateChange/' +
          this.data.id,
        submitObject
      )
        .then(() => {
          this.showMessage(
            'success',
            `Das Projekt ${this.project.name} wurde erfolgreich auf den Status "${this.nextStatus.description}" geändert`
          );
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch((err) => {
          console.error(err);
          this.showMessage(
            'error',
            'Beim Abschließen der Aufgabe ist ein Fehler aufgetreten'
          );
        });
    }
  }
};
</script>
